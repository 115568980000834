















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// eslint-disable-next-line @typescript-eslint/camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { AmChartsLogo } from '@amcharts/amcharts4/.internal/core/elements/AmChartsLogo';

am4core.useTheme(am4themes_animated);

@Component({})
export default class RadarChart extends Vue {
	@Prop() name!: string;
	@Prop() lifts!: number;
	@Prop() successfulLifts!: number;
	@Prop() liftsWithoutComplaints!: number;
	@Prop() sitesServiced!: number;
	@Prop() servicesOffered!: number;

	mounted() {
		const chart = am4core.create(this.name, am4charts.RadarChart);

		// Data
		chart.data = [
			{
				category: 'Lifts',
				value: this.lifts,
			},
			{
				category: 'Successful \n Lifts',
				value: this.successfulLifts,
			},
			{
				category: 'Lifts Without \n Complaints',
				value: this.liftsWithoutComplaints,
			},
			{
				category: 'Sites \n Serviced',
				value: this.sitesServiced,
			},
			{
				category: 'Services \n Offered',
				value: this.servicesOffered,
			},
		];

		//Category Axes
		const categoryAxis = chart.xAxes.push(
			new am4charts.CategoryAxis<am4charts.AxisRendererCircular>()
		);
		categoryAxis.dataFields.category = 'category';

		//Value Axes
		const axis = chart.yAxes.push(
			new am4charts.ValueAxis<am4charts.AxisRendererRadial>()
		);
		//axis.renderer.labels.template.disabled = true;
		axis.logarithmic = true;

		//Series
		const series = chart.series.push(new am4charts.RadarSeries());
		series.name = 'You';
		series.dataFields.valueY = 'value';
		series.dataFields.categoryX = 'category';
		series.stroke = am4core.color('#8c16bd');

		//Cursor
		// series.tooltipText = '{valueY}';
		// chart.cursor = new am4charts.RadarCursor();
		const bullet = series.bullets.push(new am4charts.CircleBullet());
		bullet.tooltipHTML = '{categoryX} : {valueY}';
		bullet.fill = am4core.color('#8c16bd');
		//bullet.circle.radius = 3;

		//scrollbar
		chart.scrollbarY = new am4core.Scrollbar();
		chart.scrollbarY.startGrip.icon.disabled = true;
		chart.scrollbarY.endGrip.icon.disabled = true;
		chart.scrollbarY.minWidth = 5;
		chart.scrollbarY.marginRight = 0;
		chart.scrollbarY.marginLeft = 10;
		chart.scrollbarY.background.fill = am4core.color('#8c16bd');

		//legend
		// chart.legend = new am4charts.Legend();
	}
}
