import { render, staticRenderFns } from "./barChart.vue?vue&type=template&id=7e313dc2&scoped=true&"
import script from "./barChart.vue?vue&type=script&lang=ts&"
export * from "./barChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e313dc2",
  null
  
)

export default component.exports