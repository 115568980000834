








import { Component, Vue, Prop } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// eslint-disable-next-line @typescript-eslint/camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { pushAll } from '@amcharts/amcharts4/.internal/core/utils/Array';

am4core.useTheme(am4themes_animated);

@Component({})
export default class BarChart extends Vue {
	@Prop() performanceValue!: number;
	@Prop() name!: string;

	mounted() {
		const chart = am4core.create(this.name, am4charts.GaugeChart);

		//Axes
		const axis = chart.xAxes.push(
			new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
		);
		axis.min = 0;
		axis.max = 100;
		axis.strictMinMax = true;
		axis.renderer.grid.template.disabled = true;
		axis.renderer.labels.template.disabled = true;

		//CategoryAxis
		const axis2 = chart.xAxes.push(
			new am4charts.CategoryAxis<am4charts.AxisRendererCircular>()
		);
		axis2.dataFields.category = 'category';
		axis2.data = [
			{
				category: 'Poor',
			},
			{
				category: 'Excellent',
			},
		];
		axis2.renderer.labels.template.location = 0.5;
		axis2.renderer.grid.template.location = 0.5;
		axis2.startLocation = 0.5;
		axis2.endLocation = 0.5;
		axis2.renderer.grid.template.disabled = true;

		//Style
		chart.innerRadius = am4core.percent(80);
		//range
		const range = axis.axisRanges.create();
		range.value = 0;
		range.endValue = 100;
		//colourz
		range.axisFill.fillOpacity = 1;
		const gradient = new am4core.LinearGradient();
		gradient.addColor(am4core.color('#d41116'));
		gradient.addColor(am4core.color('#F79E12'));
		gradient.addColor(am4core.color('#F79E12'));
		gradient.addColor(am4core.color('#4fa928'));
		gradient.addColor(am4core.color('#4fa928'));
		range.axisFill.fill = gradient;
		range.axisFill.zIndex = -1;

		//Clockhand
		const hand = chart.hands.push(new am4charts.ClockHand());
		hand.value = 0;
		//colours
		hand.fill = am4core.color('#8c16bd');
		hand.stroke = am4core.color('#8c16bd');
		hand.radius = am4core.percent(90);
		//animation
		setInterval(() => {
			hand.showValue(this.performanceValue, 2000, am4core.ease.cubicOut);
		}, 2000);
	}
}
