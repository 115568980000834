












































































































































































































































import { Component, Vue } from 'vue-property-decorator';

// Services
import { DashboardService } from '@/services/dashboard/dashboardService';

// Modules
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { DropDownType } from '@/classes/generics/classes/DropDownType';

import BarChart from '@/components/charts/barChart.vue';
import FullGauge from '@/components/charts/fullGauge.vue';
import BarChartHorizontal from '@/components/charts/barChartHorizontal.vue';
import LoadingData from '@/components/datadisplay/LoadingData.vue';
import NoData from '@/components/datadisplay/NoData.vue';
import GaugeChart from '@/components/charts/gaugeChart.vue';
import RadarChart from '@/components/charts/radarChart.vue';

@Component({
	components: {
		BarChart,
		BarChartHorizontal,
		LoadingData,
		FullGauge,
		NoData,
		GaugeChart,
		RadarChart,
	},
})
export default class Dashboard extends Vue {
	dasboardService = new DashboardService();
	suppliersModule = SuppliersModule;

	totalLifts: number | null = null;
	missedLifts: number | null = null;
	sitesServiced: number | null = null;
	ticketsAgainstSupplier: Array<DropDownType> = [];
	commonTicketCategories: Array<DropDownType> = [];
	serviceSuccessRate: number | null = null;
	ticketsRaisedAgainstYou: number | null = null;

	serviceComparison: object | null = null;

	subscription: any;

	performanceValue = 0;

	beforeDestroy() {
		this.subscription();
	}

	created() {
		this.GetDashboardData();
	}

	GetDashboardData() {
		this.totalLifts = null;
		this.missedLifts = null;
		this.sitesServiced = null;

		this.ticketsAgainstSupplier = [];
		this.commonTicketCategories = [];

		this.serviceSuccessRate = null;
		this.ticketsRaisedAgainstYou = null;

		this.serviceComparison = null;

		const supplierId = SuppliersModule.GetSelectedSupplier.SupplierId;

		this.dasboardService
			.GetReportData<number>(
				`TotalLifts?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.totalLifts = data;
			});

		this.dasboardService
			.GetReportData<number>(
				`MissedLifts?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.missedLifts = data;
			});

		this.dasboardService
			.GetReportData<number>(
				`SitesServiced?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.sitesServiced = data;
			});

		this.dasboardService
			.GetReportData<Array<DropDownType>>(
				`TicketsAgainstSupplier?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.ticketsAgainstSupplier = data;
			});

		this.dasboardService
			.GetReportData<Array<DropDownType>>(
				`CommonTicketCategories?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.commonTicketCategories = data;
			});

		this.dasboardService
			.GetReportData<number>(
				`ServiceSuccessRate?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.serviceSuccessRate = data;
			});

		this.dasboardService
			.GetReportData<number>(
				`TicketsRaisedAgainstSupplier?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
			)
			.then(data => {
				this.ticketsRaisedAgainstYou = data;
			});

		// this.dasboardService
		// 	.GetReportData<object>(
		// 		`ServiceComparison?supplierId=${supplierId}&startDate=2020-01-01&endDate=2021-01-01`
		// 	)
		// 	.then(data => {
		// 		this.serviceComparison = data;
		// 	});
	}

	mounted() {
		this.subscription = this.$store.subscribe(mutation => {
			if (mutation.type.startsWith('SelectSupplier')) {
				this.GetDashboardData();
			}
		});
	}

	// destroyed() {a}
}
