import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';

export class DashboardService {
	public GetReportData<T>(url: string): Promise<T> {
		return new Promise((resolve, reject) => {
			axiosBase.get(`dashboard/${url}`).then(
				(response: AxiosResponse<T>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}
}
