








import { Component, Vue, Prop } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// eslint-disable-next-line @typescript-eslint/camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

@Component({})
export default class BarChart extends Vue {
	@Prop() data!: Array<any>;
	@Prop() name!: string;
	@Prop() colour!: string;

	mounted() {
		const chart = am4core.create(this.name, am4charts.XYChart);

		chart.data = this.data;

		chart.paddingTop = 0;

		//scrollbar
		chart.scrollbarX = new am4core.Scrollbar();
		chart.scrollbarX.startGrip.icon.disabled = true;
		chart.scrollbarX.endGrip.icon.disabled = true;
		chart.scrollbarX.minHeight = 5;
		if (this.colour == 'purple') {
			chart.scrollbarX.background.fill = am4core.color('#8c16bd');
		} else {
			chart.scrollbarX.background.fill = am4core.color('#4fa928');
		}

		//x-axes
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'Name';
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.grid.template.disabled = true;

		categoryAxis.renderer.labels.template.adapter.add('dy', function(
			dy,
			target
		) {
			if (target.dataItem && target.dataItem.index & ((2 == 2) as any)) {
				return (dy ? dy : 0) + 25;
			}
			return dy;
		});

		//y-axes
		chart.yAxes.push(new am4charts.ValueAxis());

		// Create series
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = 'Value';
		series.dataFields.categoryX = 'Name';
		series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
		series.columns.template.strokeWidth = 0;

		if (series.tooltip) {
			series.tooltip.pointerOrientation = 'vertical';
		}

		series.columns.template.column.cornerRadiusTopLeft = 10;
		series.columns.template.column.cornerRadiusTopRight = 10;
		series.columns.template.column.fillOpacity = 0.8;

		//colours
		if (this.colour == 'purple') {
			chart.colors.list = [
				am4core.color('#6f00a1'),
				am4core.color('##a939d9'),
				am4core.color('#c657f6'),
				am4core.color('#e473ff'),
				am4core.color('#ff8fff'),
				am4core.color('#ffabff'),
				am4core.color('#e473ff'),
				am4core.color('#ff8fff'),
				am4core.color('#c657f6'),
				am4core.color('#a939d9'),
			];
		} else {
			chart.colors.list = [
				am4core.color('#308e01'),
				am4core.color('#4fa928'),
				am4core.color('#6cc544'),
				am4core.color('#88e15f'),
				am4core.color('#a5fe7a'),
				am4core.color('#c2ff95'),
				am4core.color('#a5fe7a'),
				am4core.color('#88e15f'),
				am4core.color('#6cc544'),
				am4core.color('#4fa928'),
				am4core.color('#308e01'),
				am4core.color('#007400'),
				am4core.color('#005a00'),
				am4core.color('#004200'),
			];
		}

		series.columns.template.adapter.add('fill', function(fill, target) {
			if (target.dataItem) {
				return chart.colors.getIndex(target.dataItem.index);
			}
		});

		// Puts a bar around edges of columns
		// const columnTemplate = series.columns.template;
		// columnTemplate.strokeWidth = 2;
		// columnTemplate.strokeOpacity = 1;

		chart.cursor = new am4charts.XYCursor();
	}
}
