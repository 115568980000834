





























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueSvgGauge } from 'vue-svg-gauge';

@Component({
	components: {
		VueSvgGauge,
	},
})
export default class FullGauge extends Vue {
	@Prop() data!: Array<any>;
	@Prop() name!: string;
	@Prop() gaugeColor!: string;

	// mounted() {}
}
