












import { Component, Vue, Prop } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// eslint-disable-next-line @typescript-eslint/camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

@Component({})
export default class BarChartHorizontal extends Vue {
	@Prop() data!: Array<any>;
	@Prop() name!: string;

	mounted() {
		const chart = am4core.create(this.name, am4charts.XYChart);
		chart.padding(40, 40, 40, 40);

		chart.data = this.data;
		chart.scrollbarX = new am4core.Scrollbar();

		const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.dataFields.category = 'Name';
		categoryAxis.renderer.minGridDistance = 1;
		categoryAxis.renderer.inversed = true;
		categoryAxis.renderer.grid.template.disabled = true;

		const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;

		// Create series
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.categoryY = 'Name';
		series.dataFields.valueX = 'Value';
		series.name = 'Value';

		//rounded edges
		series.columns.template.column.cornerRadiusBottomRight = 5;
		series.columns.template.column.cornerRadiusTopRight = 5;

		series.columns.template.tooltipText = '{valueX}';

		const labelBullet = series.bullets.push(new am4charts.LabelBullet());
		labelBullet.label.horizontalCenter = 'left';
		labelBullet.label.dx = 10;
		labelBullet.label.text =
			// eslint-disable-next-line quotes
			"{values.valueX.workingValue.formatNumber('#.0as')}";
		labelBullet.locationX = 1;

		chart.colors.list = [
			am4core.color('#4fa928'),
			am4core.color('#a5fe7a'),
			am4core.color('#e0ffb1'),
			am4core.color('#ffabff'),
			am4core.color('#e473ff'),
			am4core.color('#8c16bd'),
		];

		series.columns.template.adapter.add('fill', function(fill, target) {
			if (target.dataItem) {
				return chart.colors.getIndex(target.dataItem.index);
			}
		});

		//series.fill = am4core.color('#8c16bd');
		series.strokeWidth = 1;

		categoryAxis.sortBySeries = series;
		chart.cursor = new am4charts.XYCursor();
	}
}
